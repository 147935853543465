import React, {Component} from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AnimatedLines from "../components/animatedLines"
import openInNewIcon from "../images/OPEN-IN-NEW.svg"

class AuthenticityPage extends Component {

  render() {

    return (

      <Layout>
        <SEO title="Certificate of Authenticity"/>
        <section className="hero is-medium has-absolute-navbar" style={{
          position: "relative"
        }}>
          <AnimatedLines className="lines-01" style={{
            transform: "scaleX(-1) rotate(80deg)",
            position: "absolute",
            top: "50%",
            left: "-10%",
            zIndex: "0",
            width: "42%"
          }}/>
          <AnimatedLines className="lines-02" style={{
            position: "absolute",
            transform: "scaleX(-1) rotate(-20deg)",
            top: "-80%",
            right: "-8%",
            zIndex: "-1",
            width: "45%"
          }}/>
          <div className="hero-body">

          <div className="container">
            <div className="columns is-centered">
              <div className="column is-6">

                <h1 className="has-text-centered">
                  Certificate of Authenticity
                </h1>
                <p className="has-text-centered">On 3rd party test results. We believe in complete transparency. All of our products are rigorously tested through 3rd party labs to insure that every claim we make in our bottle is accurate.</p>
                <div></div>

                <br/><br/><br/><br/>
                <a href="https://reasontosmile.wpengine.com/wp-content/uploads/2020/10/Reason-to-Smile-COA-092420.pdf" className='has-text-black'>
                <div className="level is-mobile" >
                  <div className="level-left">
                  <div>
                    <h4 className="is-inline-desktop" style={{marginBottom:"0px"}}>CBD Vegan Gummies &nbsp;&nbsp;&nbsp;&nbsp;</h4><p className="is-uppercase is-inline-desktop">Acai Blast | Balance</p>
                    </div>
                    </div>
                    <div className="level-right">
                      <img className="is-inline is-pulled-right" src={openInNewIcon} alt=""/>
                    </div>
                  </div>
                </a>
                <hr/>
                <a href="https://reasontosmile.wpengine.com/wp-content/uploads/2020/10/Reason-to-Smile-COA-092420.pdf" className='has-text-black'>
                <div className="level is-mobile" >
                  <div className="level-left">
                  <div>
                    <h4 className="is-inline-desktop" style={{marginBottom:"0px"}}>CBD Vegan Gummies &nbsp;&nbsp;&nbsp;&nbsp;</h4><p className="is-uppercase is-inline-desktop">Fresh Watermelon | Balance</p>
                    </div>
                    </div>
                    <div className="level-right">
                      <img className="is-inline is-pulled-right" src={openInNewIcon} alt=""/>
                    </div>
                  </div>
                </a>
                <hr/>
                <a href="https://reasontosmile.wpengine.com/wp-content/uploads/2020/01/1912CGL0037.0088-Reason-To-Smile-Corp-VEGAN-GUMMIES-2.pdf" className='has-text-black'>
                <div className="level is-mobile" >
                  <div className="level-left">
                  <div>
                    <h4 className="is-inline-desktop" style={{marginBottom:"0px"}}>CBD Vegan Gummies &nbsp;&nbsp;&nbsp;&nbsp;</h4><p className="is-uppercase is-inline-desktop">Passion Fruit | Balance</p>
                    </div>
                    </div>
                    <div className="level-right">
                      <img className="is-inline is-pulled-right" src={openInNewIcon} alt=""/>
                    </div>
                  </div>
                </a>
                <hr/>
                <a href="https://reasontosmile.wpengine.com/wp-content/uploads/2020/02/Mighty-Mango-COA-Lot-1055.pdf" className='has-text-black'>
                <div className="level is-mobile" >
                  <div className="level-left">
                  <div>
                    <h4 className="is-inline-desktop" style={{marginBottom:"0px"}}>CBD Oil &nbsp;&nbsp;&nbsp;&nbsp;</h4><p className="is-uppercase is-inline-desktop">Mighty Mango | Energize</p>
                    <p style={{marginBottom:"-1rem"}}>500MG</p>
                    </div>
                    </div>
                    <div className="level-right">
                      <img className="is-inline is-pulled-right" src={openInNewIcon} alt=""/>
                    </div>
                  </div>
                </a>
                <hr/>
                <a href="https://reasontosmile.wpengine.com/wp-content/uploads/2020/02/Zesty-Lemon-COA-Lot-1056.pdf" className='has-text-black'>
                <div className="level is-mobile" >
                  <div className="level-left">
                  <div>
                    <h4 className="is-inline-desktop" style={{marginBottom:"0px"}}>CBD Oil &nbsp;&nbsp;&nbsp;&nbsp;</h4><p className="is-uppercase is-inline-desktop">Zesty Lemon | Energize</p>
                    <p style={{marginBottom:"-1rem"}}>500MG</p>
                    </div>
                    </div>
                    <div className="level-right">
                      <img className="is-inline is-pulled-right" src={openInNewIcon} alt=""/>
                    </div>
                  </div>
                </a>
                <hr/>
                <a href="https://reasontosmile.wpengine.com/wp-content/uploads/2020/02/Franch-Vanilla-COA-Lot-1057.pdf" className='has-text-black'>
                <div className="level is-mobile" >
                  <div className="level-left">
                  <div>
                    <h4 className="is-inline-desktop" style={{marginBottom:"0px"}}>CBD Oil &nbsp;&nbsp;&nbsp;&nbsp;</h4><p className="is-uppercase is-inline-desktop">French Vanilla | Unwind</p>
                    <p style={{marginBottom:"-1rem"}}>500MG</p>
                    </div>
                    </div>
                    <div className="level-right">
                      <img className="is-inline is-pulled-right" src={openInNewIcon} alt=""/>
                    </div>
                  </div>
                </a>
                <hr/>
                <a href="https://reasontosmile.wpengine.com/wp-content/uploads/2020/02/Mint-Bliss-COA-Lot-1058.pdf" className='has-text-black'>
                  <div className="level is-mobile" >
                    <div className="level-left">
                    <div>
                      <h4 className="is-inline-desktop" style={{marginBottom:"0px"}}>CBD Oil &nbsp;&nbsp;&nbsp;&nbsp;</h4><p className="is-uppercase is-inline-desktop">Mint Bliss | Unwind</p>
                      <p style={{marginBottom:"-1rem"}}>500MG</p>
                      </div>
                    </div>
                    <div className="level-right">
                      <img className="is-inline is-pulled-right" src={openInNewIcon} alt=""/>
                    </div>
                  </div>
                </a>

                <hr/>
                <a href="https://reasontosmile.wpengine.com/wp-content/uploads/2020/08/2007NBL1443.3364-Cosmetech-LLC-Smile-Kickstart-Mango.pdf" className='has-text-black'>
                <div className="level is-mobile" >
                  <div className="level-left">
                  <div>
                    <h4 className="is-inline-desktop" style={{marginBottom:"0px"}}>CBD Oil &nbsp;&nbsp;&nbsp;&nbsp;</h4><p className="is-uppercase is-inline-desktop">Mighty Mango | Energize</p>
                    <p style={{marginBottom:"-1rem"}}>1000MG</p>
                    </div>
                    </div>
                    <div className="level-right">
                      <img className="is-inline is-pulled-right" src={openInNewIcon} alt=""/>
                    </div>
                  </div>
                </a>
                <hr/>
                <a href="https://reasontosmile.wpengine.com/wp-content/uploads/2020/08/2007NBL1443.3365-Cosmetech-LLC-Smile-Kickstart-Lemon.pdf" className='has-text-black'>
                <div className="level is-mobile" >
                  <div className="level-left">
                  <div>
                    <h4 className="is-inline-desktop" style={{marginBottom:"0px"}}>CBD Oil &nbsp;&nbsp;&nbsp;&nbsp;</h4><p className="is-uppercase is-inline-desktop">Zesty Lemon | Energize</p>
                    <p style={{marginBottom:"-1rem"}}>1000MG</p>
                    </div>
                    </div>
                    <div className="level-right">
                      <img className="is-inline is-pulled-right" src={openInNewIcon} alt=""/>
                    </div>
                  </div>
                </a>
                <hr/>
                <a href="https://reasontosmile.wpengine.com/wp-content/uploads/2020/08/2007NBL1443.3367-Cosmetech-LLC-Smile-Unwind-Fr-Vanilla.pdf" className='has-text-black'>
                <div className="level is-mobile" >
                  <div className="level-left">
                  <div>
                    <h4 className="is-inline-desktop" style={{marginBottom:"0px"}}>CBD Oil &nbsp;&nbsp;&nbsp;&nbsp;</h4><p className="is-uppercase is-inline-desktop">French Vanilla | Unwind</p>
                    <p style={{marginBottom:"-1rem"}}>1000MG</p>
                    </div>
                    </div>
                    <div className="level-right">
                      <img className="is-inline is-pulled-right" src={openInNewIcon} alt=""/>
                    </div>
                  </div>
                </a>
                <hr/>
                <a href="https://reasontosmile.wpengine.com/wp-content/uploads/2020/08/2007NBL1443.3366-Cosmetech-LLC-Smile-Unwind-Mint.pdf" className='has-text-black'>
                  <div className="level is-mobile" >
                    <div className="level-left">
                    <div>
                      <h4 className="is-inline-desktop" style={{marginBottom:"0px"}}>CBD Oil &nbsp;&nbsp;&nbsp;&nbsp;</h4><p className="is-uppercase is-inline-desktop">Mint Bliss | Unwind</p>
                      <p style={{marginBottom:"-1rem"}}>1000MG</p>
                      </div>
                    </div>
                    <div className="level-right">
                      <img className="is-inline is-pulled-right" src={openInNewIcon} alt=""/>
                    </div>
                  </div>
                </a>
                <hr/>
                <a href="https://reasontosmile.wpengine.com/wp-content/uploads/2021/08/2103CGL0869.2158-Reason-To-Smile-Corp-Smile-CBD-Body-Lotion.pdf" className='has-text-black'>
                <div className="level is-mobile" >
                  <div className="level-left">
                  <div>
                    <h4 className="is-inline-desktop" style={{marginBottom:"0px"}}>CBD Body Lotion &nbsp;&nbsp;&nbsp;&nbsp;</h4><p className="is-uppercase is-inline-desktop">Cocoa Butter | Rejuvenate</p>
                    </div>
                    </div>
                    <div className="level-right">
                      <img className="is-inline is-pulled-right" src={openInNewIcon} alt=""/>
                    </div>
                  </div>
                </a>
                <hr/>
                <a href="https://reasontosmile.wpengine.com/wp-content/uploads/2021/08/2103CGL0869.2157-Reason-To-Smile-Corp-Smile-CBD-Pain-Cream.pdf" className='has-text-black'>
                <div className="level is-mobile" >
                  <div className="level-left">
                  <div>
                    <h4 className="is-inline-desktop" style={{marginBottom:"0px"}}>CBD Pain Cream &nbsp;&nbsp;&nbsp;&nbsp;</h4><p className="is-uppercase is-inline-desktop">Menthol | Rejuvenate</p>
                    </div>
                    </div>
                    <div className="level-right">
                      <img className="is-inline is-pulled-right" src={openInNewIcon} alt=""/>
                    </div>
                  </div>
                </a>
                <hr/>
                <a href="https://reasontosmile.wpengine.com/wp-content/uploads/2021/08/2103CGL0869.2159-Reason-To-Smile-Corp-Smile-CBD-Face-Cream.pdf" className='has-text-black'>
                <div className="level is-mobile" >
                  <div className="level-left">
                  <div>
                    <h4 className="is-inline-desktop" style={{marginBottom:"0px"}}>CBD Face Cream &nbsp;&nbsp;&nbsp;&nbsp;</h4><p className="is-uppercase is-inline-desktop">Argan Oil | Rejuvenate</p>
                    </div>
                    </div>
                    <div className="level-right">
                      <img className="is-inline is-pulled-right" src={openInNewIcon} alt=""/>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          </div>

        </section>
        <section className="section is-medium">

          <div className="container">
            <div className="columns is-vcentered">

              <div className="column is-relative happiness-inside-image" style={{
                backgroundImage: `url(https://reasontosmile.wpengine.com/wp-content/uploads/2020/01/WebsiteFinals-1.jpg)`
              }}/>
              <div className="column is-5 is-offset-1 has-text-centered-mobile">

                <h1 className="is-h1-desktop-mobile">
                  Happiness Inside
                </h1>
                <br/>
                <h4 className="">Smiles are contagious. Smiles have the power to change the world. Our CBD products are designed to help you tune into your best self, so you can be here & now… happily.</h4>
                <br/>
                <Link to="/story">

                  <button className="select-button is-primary">SEE OUR STORY</button>
                </Link>
              </div>

            </div>

          </div>

        </section>
      </Layout>
    )
  }
}

export default AuthenticityPage
